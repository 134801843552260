<template>
  <VNav class="!py-[5px] !px-5">
    <VNavDisclosure class="vvd-theme-alternate" :open="isOpen" :label="data.label">
      <HyperLink v-for="item in data.items" :key="item._uid" :to="item.link">
        <template #default="{ navigate }">
          <VNavItem
            class="cursor-pointer vvd-theme-alternate"
            :text="item.label"
            @click="() => performItemNavigation(navigate, emitCloseSidebar)"
          >
          </VNavItem>
        </template>
      </HyperLink>
    </VNavDisclosure>
  </VNav>
</template>

<script setup lang="ts">
import { type PropType, ref } from 'vue';
import { VNav, VNavDisclosure, VNavItem } from '@vonage/vivid-vue';
import HyperLink from '@/components/utils/HyperLink.vue';
import { performItemNavigation } from '@/utils/navigation';
import type { NavigationMenuItem } from '@/types/storyblok';

const isOpen = ref(false);

defineProps({
  data: { type: Object as PropType<NavigationMenuItem>, required: true },
});

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const emitCloseSidebar = () => emit('close');
</script>
