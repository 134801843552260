<template>
  <VMenu :anchor="anchor?.$el" :open="open" auto-dismiss class="menu vvd-theme-alternate">
    <HyperLink v-for="item in items" v-slot="{ navigate }" :key="item._uid" :to="item.link || '#'">
      <VMenuItem :text="item.label" class="menu-item" @click="() => performItemNavigation(navigate, closeNavbarMenu)" />
    </HyperLink>
  </VMenu>
</template>

<script setup lang="ts">
import { type PropType } from 'vue';
import { VMenu, VMenuItem } from '@vonage/vivid-vue';
import HyperLink from '@/components/utils/HyperLink.vue';
import { performItemNavigation } from '@/utils/navigation';
import NavbarButton from '@/components/navbar/common/NavbarButton.vue';
import type { NavigationLinkItem } from '@/types/storyblok';

defineProps({
  open: { type: Boolean, required: true },
  anchor: { type: Object as PropType<InstanceType<typeof NavbarButton>> },
  items: { type: Array as PropType<NavigationLinkItem[]>, required: true },
});

const emit = defineEmits(['close']);

const closeNavbarMenu = () => {
  emit('close');
};
</script>
