<template>
  <MobileDrawer :open="open" @close="$emit('close')">
    <v-nav open class="vvd-theme-alternate">
      <navbar-item v-for="item in data.items" :key="item._uid" :item="item" :is-mobile="true" @close="close" />
      <mobile-external-link v-for="link in data.externalLinks" :key="link._uid" v-bind="link" @close="$emit('close')" />
    </v-nav>
  </MobileDrawer>
</template>

<script setup lang="ts">
import { type PropType } from 'vue';
import { VNav } from '@vonage/vivid-vue';
import MobileDrawer from '@/components/navbar/mobile/sideDrawer/MobileDrawer.vue';
import type { NavigationWidget } from '@/types/storyblok';
import MobileExternalLink from '@/components/navbar/common/ExternalLink.vue';
import NavbarItem from '@/components/navbar/common/NavbarItem.vue';

defineProps({
  open: { type: Boolean, required: true },
  data: {
    type: Object as PropType<NavigationWidget>,
    required: true,
  },
});
const emit = defineEmits<{
  (event: 'close'): void;
}>();

const close = () => {
  emit('close');
};
</script>
