<template>
  <div class="root">
    <NavbarButton ref="button" @click="open = !open">
      {{ data.label }}
      <template #icon><VIcon class="icon" name="chevron-down-line" :size="-6" /></template>
    </NavbarButton>
    <NavbarMenuItems :items="data.items" :anchor="button" :open="open" @close="open = false" />
  </div>
</template>

<script setup lang="ts">
import { type PropType, ref } from 'vue';
import { VIcon } from '@vonage/vivid-vue';
import NavbarButton from '@/components/navbar/common/NavbarButton.vue';
import type { NavigationMenuItem } from '@/types/storyblok';
import NavbarMenuItems from '@/components/navbar/main/NavbarMenuItems.vue';

defineProps({
  data: { type: Object as PropType<NavigationMenuItem>, required: true },
});

const open = ref(false);
const button = ref<InstanceType<typeof NavbarButton> | undefined>();
</script>

<style scoped lang="scss">
.icon {
  margin-left: 10px;
  color: var(--vvd-color-canvas);
}

.root {
  :deep(.menu) {
    background-color: rgb(37, 37, 37);
    color: var(--vvd-color-canvas);
  }

  :deep(.menu-item) {
    --vvd-menu-item-accent-primary: var(--vvd-color-canvas-text);
    --vvd-menu-item-accent-faint: var(--vvd-color-neutral-100);
    --vvd-menu-item-accent-soft: var(--vvd-color-neutral-100);
    --vvd-menu-item-accent-dim: var(--vvd-color-neutral-100);
    --vvd-menu-item-accent-pale: var(--vvd-color-neutral-100);
  }
}
</style>
