<template>
  <navigation-link :link="link" @click="close">
    <div
      class="external-link-items flex flex-row items-center justify-between text-sm font-normal h-10 lg:h-auto py-[5px] px-[34px] lg:py-0 lg:px-0"
    >
      <div>
        <span>{{ label }}</span>
      </div>
      <div>
        <v-icon name="arrow-outbound-line" class="arrow-icon flex lg:ml-1 ml-0 text-[8px]" />
      </div>
    </div>
  </navigation-link>
</template>
<script setup lang="ts">
import { VIcon } from '@vonage/vivid-vue';
import NavigationLink from '@/components/navbar/common/NavigationLink.vue';

defineProps({
  label: { type: String, required: true },
  link: { type: String, required: true, default: '' },
});

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const close = () => {
  emit('close');
};
</script>
<style scoped lang="scss">
@import '../../../styles/media';

.external-link-items {
  background: var(--vvd-color-canvas);
  color: var(--vvd-color-neutral-700);

  &:hover {
    color: var(--vvd-color-canvas-text);
    background: var(--vvd-color-neutral-100);
  }
  @media screen and (min-width: $laptop) {
    font: var(--vvd-typography-base);
    background: none;
    color: var(--portal-navbar-text-color) !important;

    &:hover {
      text-decoration: underline;
      color: var(--vvd-color-canvas-text);
      background: none;
    }
  }
}
.arrow-icon {
  color: var(--vvd-color-canvas-text);
  @media screen and (min-width: $laptop) {
    color: var(--portal-navbar-text-color);
  }
}
</style>
